.footer {
  background-color: var(--footer-bg-color);
}

.wrapper {
  padding-left: 16px;
  padding-right: 16px;
}

.socialMediaPlatformsContainer {
  padding-bottom: var(--footer-social-media-platform-padding-bottom);
  padding-top: var(--footer-social-media-platform-padding-top);
}

.linksContainer {
  padding-top: var(--footer-links-padding-top);
}

:global(.theme-jc) .jaycarLogo {
  display: block;
}

:global(.theme-rtm) .rtmLogoMobile {
  display: block;
}

@media (--desktop) {
  .linkWrapper {
    min-width: 483px;
  }

  .callCentreTitle {
    --typography-body-large-font-size: var(--typography-body-regular-font-size);
    --typography-body-large-line-height: var(
      --typography-body-regular-line-height
    );
    --typography-body-large-letter-spacing: var(
      --typography-body-regular-letter-spacing
    );
  }

  .callCenterList {
    row-gap: var(--footer-call-centre-list-gap);
  }

  .wrapper {
    margin-top: 0;
    padding-left: 80px;
    padding-right: 80px;
  }

  .socialMediaPlatformsContainer {
    padding-top: var(--footer-social-media-platform-padding-top-lg);
  }

  :global(.theme-rtm) .rtmLogoMobile {
    display: none;
  }

  :global(.theme-rtm) .rtmLogoDesktop {
    display: block;
  }
}

@media (--desktop-m) {
  .linkWrapper {
    min-width: 879px;
  }

  .callCentreContainer {
    padding-right: 47px;
  }
}

@media screen and (--mobile) {
  .zendeskMobileFooter {
    padding-bottom: 6rem;
  }
}
